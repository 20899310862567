.margin_top_none {
	margin-top: 0px;
}

.midium-font{
	font-size: 15px;
	letter-spacing:-0.015em;
}
.navigation > li > a {
	font-weight: bolder;
}
.text-bold{
	font-weight: bolder;
}
.has-stik{
  color: red;
  font-weight: bold;
}
.error{
  color: red;
}
.row > .col-sm-12 > .alert{
	margin-bottom: 0px;
	margin-top: 1%;
	padding-left: 15px;
}
.form-control:focus,
input[type=checkbox]:focus,
input[type=radio]:focus {
   background: #FFFFFF;
   outline: 1px solid #3c8dbc;
}

.panel-body .btn:focus,
.box .btn:focus,
.box button:focus,
.box a:focus,
.box button:active {
   outline: 1px solid #3c8dbc;
}

.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td{
	border-top:0px solid #ddd;
}

.custom-div{
	width:12.5%;
	float:left;
	padding:12px 14px;
}
.caption1{
	height: 50px;
	text-align: center;
}

.margin-top-5px{
	margin-top: 5px;
}

.dataTables_wrapper .dataTables_filter input{
	margin-left: 0.5em;
}

.dataTables_length{
	padding-right: 0.7em;
}

.dataTables_info{
	padding-left: 0.7em;
}
.dataTables_paginate .paging_simple_numbers{
	padding-right: 1.00em;
}
th{
	font-weight: bold;
}

.page-title .set-height{
	height: 34px;
}

.thumbnail{
	height: 225px;
}

.thumbnail > .thumb > img {
	height: 161.70px;
}

/* form master modal CSS */
.form_master_modal > .modal-dialog > .modal-content{
	height: 450px;
}

.table-bordered > thead > tr > td, .table-bordered > thead > tr > th{
    border-bottom-width: 1px;
    padding: 5px;
    font-size: 13px;
     border: 1px solid #ddd;
}
.table-bordered > tbody > tr > td, .table-bordered > tbody > tr > td{
    padding: 5px;
    font-size: 13px;
    border: 1px solid #ddd;
}
.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td{
	vertical-align: top;
}
.valign{
	vertical-align: top;
}
.label-width{
	width: 200px;
}
.validation-width{
	width: 500px;
}

form .select2-container--default .select2-selection--multiple, form .select2-selection .select2-selection--multiple {
    border: 1px solid #d2d6de;
    border-radius: 0;
}

.select2-container--focus {
    border: solid #3c8dbc 1px;
}
/* Used for width of admore td*/
.firts_column{
	width:10%;
}
.second_column{
	width:20%;
}
.third_column{
	width:30%;
}
.fourth_column{
	width:40%;
}

.table_borderd > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td{
	border-top:1px solid #ddd;
}

.margin_bottom{
	margin-bottom: 1.5em;
}

.margin_bottom_none{
	margin-bottom: 0px;
}

.margin_top{
	margin-top: 5em;
}
.padding{
	padding-left: 0.8em;
}

.mobile{
	font-size: 1.5em;
    margin-left: 8px;
}
.display a {
	display: block;
	color: #000;

}
.icon-mobile{
	color: #98c34a;
	margin-right: 0.4em;
}
.icon-satellite{
	color: #9c27b0;
	margin-right: 0.4em;
}
.icon-power{
	color: #FFC0CB;
	margin-right: 0.4em;
}
.iconfire{
	color: #f44b36;
	margin-right: 0.4em;
}
.font-icontext{
	font-size: 18px;
}

.margin-top-30{
	margin-top: 30px;
}
.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control{
	background-color : #eee;
	opacity :1;
}
.inr-size{
	font-size: 30px !important;
}
.list-size{
	font-size: 16px;
	font-weight: bold;
}

.uppercase{
	text-transform: uppercase;
}
.lowercase{
	text-transform: lowercase;
}

/* User Agreement Print CSS Start */
@media print {
    div.page-header {
        display: none;
    }
    /*div.content-wrapper{
    	padding: 0px;
    	margin: 0px;
    }*/

    div.panel{
    	border:none;
    }

	p.lockerP{
		font-size:15px;
		width:100%;
		border-bottom:2px dotted black;
	}
    div.lockerDiv{
    	width:40%;
		border-bottom:2px dotted black;
		float:left;
    	word-wrap: break-word;
    }
    div.lockerDiv50{
    	word-wrap: break-word;
    	width:60%;
		border-bottom:2px dotted black;
		float:left;
    }
    div.lockerDiv30{
		width:50%;
		border-bottom:2px dotted black;
		float:left;
    	word-wrap: break-word;

	}
	div.lockerDiv30Mo{
		width:35%;
		border-bottom:2px dotted black;
		float:left;
    	word-wrap: break-word;

	}
	div.lockerDiv70PSK{
		width:65%;
		border-bottom:2px dotted black;
		float:left;
    	word-wrap: break-word;
	}
	div.font_15{
		overflow:hidden;
	}
	div.alert{
		display: none;
	}
	div.sidebar{
		display: none;
	}
}
/* User Agreement Print CSS End */

/* Common css  */
table.dataTable>thead>tr>th{
	padding-right: 0px;
}
.table-scroll {
	overflow-x:scroll;

}

/* Common css end */

/* Product Module */

.product_image {
	border: 1px solid #dcdcdc;
}

/* Group Module */

.group-discount tr th  a > i.plus{
	font-size: 18px;
}

.panel-heading .accordion-toggle:after {
    font-family: 'Glyphicons Halflings';  /* essential for enabling glyphicon */
    content: "\e114";    /* adjust as needed, taken from bootstrap.css */
    float: right;        /* adjust as needed */
    color: grey;         /* adjust as needed */
}
.panel-heading .accordion-toggle.collapsed:after {
    content: "\e080";
}

.dataTables_scroll .dataTables_scrollBody th, .dataTables_scroll .dataTables_scrollBody td{
	white-space: normal;
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
    padding: 12px 15px;

}
.navbar-nav > li > .dropdown-menu {
    margin-top: 0px;
}
@media (min-width: 769px) {
	.sidebar {
	    display: table-cell;
	    vertical-align: top;
	    width: 250px;
	    padding-right: 10px;
	}
}